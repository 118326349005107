import gql from 'graphql-tag'
import project from '../fragments/project'

export const query = gql`
  mutation UpdateOneProject($where: ProjectWhereUniqueInput!, $data: ProjectUpdateInput!) {
    updateOneProject(where: $where, data: $data) { ...ProjectFragment }
  }
  ${project}
`

export default query
