<template>
  <div>
    <v-row class="pt-5">
      <v-col>
        <v-tabs
          v-model="tab"
          class="d-flex project-tab"
        >
          <v-tab
            v-for="item of projectTabs"
            :key="item.link"
            class="project-tab"
            :href="item.link"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item
        value="overview"
      >
        <ProjectOverview :project="project" />
      </v-tab-item>
      <v-tab-item
        value="owners"
      >
        <ProjectOwners :project="project" :owners="project.owners"/>
      </v-tab-item>
      <v-tab-item
        value="tested-versions"
      >
        <ProjectTestedVersions :versions="project.testedVersions" :sourceVersion="project.unityVersion" :projectId="project.id"/>
      </v-tab-item>
      <v-tab-item value="project-bugs">
        <ProjectBugs :bugs="project.bugs" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ProjectBugs from '@/components/projectPageComponents/ProjectBugs.vue'
import ProjectOverview from '@/components/projectPageComponents/Overview.vue'
import ProjectOwners from '@/components/projectPageComponents/Owners.vue'
import ProjectTestedVersions from '@/components/projectPageComponents/TestedVersions.vue'

export default {
  name: "ProjectTabs",
  components: {
    ProjectBugs,
    ProjectOverview,
    ProjectOwners,
    ProjectTestedVersions
  },
  props: {
    project: {}
  },
  data: () => ({
    tab: "#overview",
    projectTabs: [
      {
        name: "Overview",
        link: "#overview"
      },
      {
        name: "Owners",
        link: "#owners"
      },
      {
        name: "Tested versions",
        link: "#tested-versions"
      },
      // { Hide till whole feature will be developed
      //   name: "Project bugs",
      //   link: "#project-bugs"
      // },
    ]
  })
}
</script>