import gql from 'graphql-tag'
import project from '../fragments/project'

export const query = gql`
  mutation CreateOneProject($data: ProjectCreateInput!) {
    createOneProject(data: $data) { ...ProjectFragment }
  }
  ${project}
`

export default query
