import gql from 'graphql-tag'
import user from '../fragments/user'

export const query = gql`
  mutation UpdateOneUserFavourites($projectId: String!) {
    updateOneUserFavourites(projectId: $projectId) {
      ...UserFragment
    }
  }
  ${user}
`

export default query
