import gql from 'graphql-tag'

export const query = gql`
  query Me {
    me {
      id
      slackId,
      slackPicture,
      isAdmin
      favouriteProjects {
        id
      }
      watchedProjects {
        id
      }
      ownedProjects {
        id
      }
    }
  }
`

export default query
