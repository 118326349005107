import gql from 'graphql-tag'
import unityStream from '../fragments/unityStream'

export const query = gql`
  query FindManyUnityStream($where: UnityStreamWhereInput, $orderBy: [UnityStreamOrderByWithRelationInput]) {
    findManyUnityStream(where: $where, orderBy: $orderBy) {
      ...UnityStreamFragment
    }
  }
  ${unityStream}
`

export default query
