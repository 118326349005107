import gql from 'graphql-tag'
import platform from '@/gql/fragments/platform'
import renderPipeline from '@/gql/fragments/renderPipeline'
import testedVersion from "@/gql/fragments/testedVersion"
import tag from "@/gql/fragments/tag"
import unityStream from "@/gql/fragments/unityStream"

export const query = gql`
  fragment ProjectFragment on Project {
    id
    name
    link
    description
    imageLink
    created
    recommended
    projectSize
    unityVersion
    owners {
      name
      id
      slackId
      slackPicture
    }
    unityStreamVersion {
      ...UnityStreamFragment
    }
    platforms {
      ...PlatformFragment
    }
    renderPipelines {
      ...RenderPipelineFragment
    }
    testedVersions {
      ...TestedVersionFragment
    }
    tags {
      ...TagFragment
    }
    primaryTags {
      ...TagFragment
    }
    favoredBy {
      id
    }
  }
  ${unityStream}
  ${platform}
  ${renderPipeline}
  ${testedVersion}
  ${tag}
`

export default query