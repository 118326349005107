import gql from 'graphql-tag'
import user from '../fragments/user'

export const query = gql`
  query FindUniqueUser($where: UserWhereUniqueInput!) {
    findUniqueUser(where: $where) {
      ...UserFragment
    }
  }
  ${user}
`

export default query