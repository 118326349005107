<template>
	<div>
    <v-overlay
      v-if="$apollo.queries.projects.loading"
      absolute
      color="primary"
    >
      <LoadingAnimation />
    </v-overlay>
    <HomeAppBar />
    <div class="background-bar" />
    <v-row class="d-flex justify-center">
      <div class="search-box mx-6">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="searchInput"
          class="transition-swing ma-0 pa-0"
          label="Search..."
          hide-details
          single-line
          justify-sm
          rounded
          clearable
        />
      </div>
    </v-row>
    <div class="mx-5 mx-sm-2 mx-md-10 px-xs-5">
      <v-row class="d-flex justify-center py-sm-4 title">
          <h1 class="mt-3">Projects</h1>
      </v-row>
      <v-row class="px-sm-5">
        <v-col
          cols="12"
          sm="4"
          md="3"
          lg="2"
          xl="1"
          class="px-0"
        >
          <v-autocomplete
            v-model="selectedOrder"
            class="sortField"
            :items="orderBy"
            outlined
            dense
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          sm="5"
          md="5"
          lg="4"
          xl="2"
        >
          <v-tabs
            v-model="tab"
            class="d-flex justify-center"
          >
            <v-tab
              href="#card-view"
            >
              <v-icon size="19" class="mr-1">
                mdi-view-grid-outline
              </v-icon>
              Card view
            </v-tab>
            <v-tab
              href="#table-view"
            >
              <v-icon size="24" class="mr-1">
                mdi-view-list-outline
              </v-icon>
              Table view
            </v-tab>
          </v-tabs>
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          sm="3"
          md="3"
          lg="2"
          xl="1"
          class="d-flex justify-end px-0"
        >
          <v-btn
            outlined
            class="filter-btn"
            :class="{'grey lighten-3': expand}"
            @click="expand = !expand"

          >
          <v-icon class="pr-2" size="20">
            mdi-filter-variant
          </v-icon>
            Filters
          </v-btn>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-show="expand" class="px-sm-2">
          <FilterField title="Tags" :properties="tag" v-model="selectedTags" />
          <FilterField title="Render Pipeline" :properties="renderPipeline" v-model="selectedRenderPipelines" />
          <FilterField title="Platform" :properties="platform" :selectedItems="selectedPlatforms" v-model="selectedPlatforms" />
          <FilterField title="Unity Stream" :properties="unityStream" v-model="selectedStreams" />
        </v-row>
      </v-expand-transition>
      <v-tabs-items v-model="tab">
        <v-tab-item
          value="card-view"
        >
          <v-row class="pa-0 mt-5 ma-0">
            <v-col
              :v-if="$apollo.queries.projects.loading"
              v-for="project in filteredProjects"
              :key="project.id"
              class="pa-0 px-0 pa-sm-0 py-3"
              cols="12"
              xl="2"
              lg="3"
              md="6"
              sm="6"
            >
              <ProjectCard :project="project" @clickedTag="(i) => sortClickedTag(i)"/>
            </v-col>
            <v-col
              class="d-flex flex-column justify-space-between align-center pt-10 nothing-found"
              v-if="filteredProjects.length === 0 && !$apollo.queries.projects.loading"
            >
              <v-img
                max-width="450"
                class="align-center"
                :src="require(`@/assets/notFound.svg`)"
              />
              <div
                class="message-text font-weight-light pt-9"
              >
                No matching projects found
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          value="table-view"
        >
          <ProjectsTable :projects="filteredProjects" @filterBy="(i) => sortClickedTag(i)" />
        </v-tab-item>
      </v-tabs-items>
    </div>
	</div>
</template>

<script>
import HomeAppBar from "../components/HomeAppBar.vue"
import ProjectsTable from "../components/ProjectsTable.vue"
import FilterField from "@/components/FilterField.vue"
import ProjectCard from "@/components/ProjectCard.vue"
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import _ from 'lodash'

export default {
  name: "HomePage",
  data: () => ({
    orderBy: ['Recently added', 'Oldest add', 'My projects', 'My favorite', 'Popular', 'Recommended'], // 'Watched projects' also goes here if someone will implement this feature in future
    selectedOrder: 'Recently added',
    expand: false,
    tab: 'card-view',
    platform: [],
    tag: [],
    unityStream: [],
    renderPipeline: [],
    projects: [],
    searchInput:'',
    selectedTags: [],
    selectedRenderPipelines: [],
    selectedPlatforms: [],
    selectedStreams: []
  }),
  components: {
    HomeAppBar,
    FilterField,
    ProjectCard,
    LoadingAnimation,
    ProjectsTable
  },
  computed: {
    filteredProjects: function() {
      let tempProjects = this.projects

      // Search input
      if (this.searchInput != '' && this.searchInput) {
        tempProjects = tempProjects
          .filter(project => {
            return Object.values(_.pickBy(project, _.isString)).some(prop => prop.toLowerCase().includes(this.searchInput.toLowerCase()))
        })
      }

      // Filter options
      if (this.selectedOrder === 'Recommended') 
        tempProjects = tempProjects
          .filter( project => project.recommended === true )
      if (this.selectedOrder === 'My projects') 
        tempProjects = tempProjects
          .filter( project => {
            return this.me.ownedProjects.some(({id}) => id == project.id)
          })
      // if (this.selectedOrder === 'Watched projects')  // maybe change naming?
      //   tempProjects = tempProjects
      //     .filter( project => {
      //       return this.me.watchedProjects.some(({id}) => id == project.id)
      //     })
      if (this.selectedOrder === 'My favorite')
        tempProjects = tempProjects
          .filter( project => {
            return this.me.favouriteProjects.some(({id}) => id == project.id)
          })

      // Sort options
      tempProjects = tempProjects.sort((a, b) => {
        if (this.selectedOrder == 'Recently added')
          return Date.parse(b.created) - Date.parse(a.created)
        if (this.selectedOrder === 'Oldest add')
          return Date.parse(a.created) - Date.parse(b.created)
        if (this.selectedOrder === 'Popular')
          return b.favoredBy.length - a.favoredBy.length
      })

      // Filter by tags
      return tempProjects
        .filter(project => {
          return _.every(this.selectedTags, tag => {
            return (project.allTags || []).map(selectedTag => selectedTag.id).includes(tag.id)
          })
        })
        .filter(project => {
          return _.every(this.selectedRenderPipelines, tag => {
            return (project.allTags || []).map(selectedTag => selectedTag.id).includes(tag.id)
          })
        })
        .filter(project => {
          return _.every(this.selectedPlatforms, tag => {
            return (project.platforms || []).map(selectedTag => selectedTag.id).includes(tag.id)
          })
        })
        .filter(project => {
          return _.every(this.selectedStreams, tag => {
            return (project.verifiedVersions || []).map(selectedTag => selectedTag.id).includes(tag.id)
          })
        })
    },
  },
  methods: {
    sortClickedTag(tag) {
      if(!this.expand) this.expand = true 

      switch (tag.__typename) {
        case 'Tag':
          if(!_.some(this.selectedTags, tag)) this.selectedTags.push(tag)
          break;
        case 'Platform':
          if(!_.some(this.selectedPlatforms, tag)) this.selectedPlatforms.push(tag)
          break;
        case 'RenderPipeline':
          if(!_.some(this.selectedRenderPipelines, tag)) this.selectedRenderPipelines.push(tag)
          break;
        case 'UnityStream':
          if(!_.some(this.selectedStreams, tag)) this.selectedStreams.push(tag)
          break;
      }
    }
  },
  apollo: {
    platform () {
      return this.$apolloHelper.findManyPlatform
    },
    tag () {
      return this.$apolloHelper.findManyTag
    },
    unityStream () {
      return this.$apolloHelper.findManyUnityStream
    },
    renderPipeline () {
      return this.$apolloHelper.findManyRenderPipeline
    },
    projects () {
      return this.$apolloHelper.findManyProject
    },
    me () {
      return {
        ...this.$apolloHelper.me
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.background-bar {
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  height: 80px;
  background: #f3f3f4;
  display: block;
}

.search-box {
  display: flex;
  width: 630px;
  height: 64px;
  position: relative;
  background-color: white;
  transform: translateY(-20px);
  z-index: 2;
  align-items: center;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 10%);
  border-radius: 8px;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  }
}

.filter-btn {
  text-transform: capitalize;
  font-size: inherit;
  width: 115px;
  box-sizing: border-box;
  border:1px solid #9e9e9e;
  color: #212121;
  font-weight: 400;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.v-tab {
  font-size: small;
}

.title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
}

.sortField {
  z-index: 21;
}

.message-text {
  font-size: 1.5rem!important;
}
</style>