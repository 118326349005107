<template>
  <div class="pb-8">
    <ConfirmDialog ref="confirm" />
    <v-row class="pt-5">
      <v-col>
        <h3 class="font-weight-light">
          COMMENTS
        </h3>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end ma-1 mr-12 pr-3 text-caption"><a href="https://markdown-it.github.io/" target="_blank">Markdown Editor</a></v-row>
    <v-row class="ma-1 pb-4">
      <div class="d-flex justify-end align-top pa-1 pt-0 mr-2">
          <v-avatar v-if="me" size="40" class="">
            <v-img :src="me.slackPicture"></v-img>
          </v-avatar>
          <v-avatar v-else size="40" left>
            <v-icon size="45" dark color="primary">
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </div>
        <div class="comments">
          <v-textarea
            v-model="comment"
            label="Add a comment... (plain text or markdown)"
            rows="2"
            auto-grow
            hide-details
            append-outer-icon="mdi-send"
            @click:append-outer="createComment()"
            dense
            outlined
          />
        </div>
    </v-row>
    <!-- <v-divider class="mt-5 pb-2" /> -->
    <div
      v-for="comment in commentsCopy"
      :key="comment.id"
    >
      <v-divider class="mt-2 pb-2" />
      <v-row class="ma-1 my-2 mt-3">
        <div class="d-flex justify-end align-top pa-1 mr-2">
          <a @click="openTab(comment.user.slackId)">
          <v-avatar v-if="comment.user.slackPicture" size="40" class="">
            <v-img :src="comment.user.slackPicture"></v-img>
          </v-avatar>
          <v-avatar v-else size="35" color="black">
            <v-icon size="35" dark>
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </a>
        </div>
        <div class="comments">
          <v-row>
            <div>
              <v-col cols="12" class="ma-1 pt-2 pb-0 mb-0 commenter">
                <a @click="openTab(comment.user.slackId)">{{ comment.user.name }}</a>
                <span>
                  <v-chip
                    v-if="isAuthor(comment.user.id)"
                    class="ml-2"
                    x-small
                    color="blue-grey"
                    text-color="white"
                  >
                    Owner
                  </v-chip>
                </span>
              </v-col>
              <v-col cols="12" class="mx-1 py-0 date">
                <span v-if="comment.created == comment.updated">{{ comment.created | moment('from', 'now') }}</span><span v-else>updated {{ comment.updated | moment('from', 'now') }}</span>
              </v-col>
            </div>
            <v-spacer></v-spacer>
            <div v-if="isCommenter(comment.user.id)" class="ma-1 pt-2 d-flex justify-end ">
              <span v-if="!comment.editMode">
                <v-btn
                  v-for="button in commentButtons"
                  :key="button.name"
                  icon
                  :color="button.color"
                  @click="button.action ? button.action(comment) : () => {}"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs"
                        v-on="on">
                        {{ button.icon }}
                      </v-icon>
                    </template>
                    <span> {{ button.tooltip }} </span>
                  </v-tooltip>
                </v-btn>
              </span>
            </div>
          </v-row>
          <v-row>
            <v-col class="ma-1 py-1" v-if="!comment.editMode">
              <div v-html="markdownToHtml(comment.comment)" class="description"></div>
            </v-col>
            <v-col class="mx-1 px-2 pt-1 pb-3" v-else>
              <div class="d-flex justify-between">
              <v-textarea
                class="classe mx-0 px-0"
                width="500px"
                v-model="comment.editedComment"
                rows="1"
                auto-grow
                hide-details
                dense
                outlined
              />
              <v-btn
                small
                color="red lighten-5"
                class="mx-1 px-6 py-5 red--text"
                elevation="0"
                @click="comment.editMode = false; comment.editedComment = comment.comment"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                small
                class="px-6 py-5 white--text"
                elevation="0"
                @click="updateComment(comment)"
              >
                Save changes
              </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it'
import _ from 'lodash'
import ConfirmDialog from "@/components/ConfirmDialog.vue"


export default {
  name: "ProjectComments",
  props: {
    owners: [],
    projectId: null,
    value: {
    },
  },
  components: {
    ConfirmDialog
  },
  data: () => ({
    attrs: [],
    loading: {
        create: false
    },
    comment: "",
    me: false,
    editMode: false,
    commentsCopy: []
  }),
  created() {
    this.comments = _.orderBy(this.comments, ['created'], ['desc'])
    this.commentsCopy = this.comments.map((item) => ({
      ...item,
      editedComment: item.comment,
      editMode: false
    }))
  },
  apollo: {
    me () {
      return {
        ...this.$apolloHelper.me
      }
    }
  },
  computed: {
    comments: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    createPayload () {
      if (!this.comment) return {}
      return {
        data: {
          user: {
            connect: {
              id: "0"
            }
          },
          project: {
            connect: {
              id: this.projectId
            }
          },
          comment: this.comment,
        }
      }
    },
    commentButtons() {
      return [
        {
          name: "edit",
          icon: "mdi-pencil-outline",
          tooltip: "Edit comment",
          color: "primary",
          action: (comment) => {
            comment.editMode = true
          }
        },
        {
          name: "delete",
          icon: "mdi-delete-outline",
          tooltip: "Delete comment",
          color: "red",
          action: async (comment) => {
            try {
                if (
                  await this.$refs.confirm.open(
                    "Are you sure?",
                    "This Comment will be <b>deleted permanently</b>."
                  )
                ){
                  await this.$apolloHelper.deleteOneComment.mutate(comment.id)
                  this.commentsCopy = this.commentsCopy.filter( item => item.id !== comment.id)
                }
              } catch (error) {
              console.log(error)
            }
          }
        }
      ]
    }
  },
  methods: {
    openTab(userSlackId) {
      window.open("https://unity.slack.com/team/" + userSlackId, "_blank");
    },
    markdownToHtml(text) {
      var md = new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true
      });
      return (text) ? md.render(text) : ""
    },
    isAuthor(id) {
      return _.includes(this.owners, id)
    },
    isCommenter(id) {
      return id == this.me.id
    },
    async createComment () {
      if (this.comment !== "" ) {
        this.loading.create = true
        try {
          await this.$apolloHelper.createOneComment.mutate(this.createPayload)
          this.comment = null
          this.comments = _.orderBy(this.comments, ['created'], ['desc'])
          this.commentsCopy = this.comments.map((item) => ({
            ...item,
            editedComment: item.comment,
            editMode: false
          }))
        } catch (error) {
          console.log(error)
        }
        this.loading.create = false
      }
    },
    async updateComment(comment) {
      let payload = {
        where: {
          id: comment.id
        },
        data: {
          comment: { set: comment.editedComment }
        }
      }
      try {
        await this.$apolloHelper.updateOneComment.mutate(payload)
        comment.comment = comment.editedComment
        comment.updated = new Date()
        comment.editMode = false
      } catch (error) {
        console.log(error)
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.comments {
  width: 95%;
}
.commenter {
  font-size: 1.1rem;
}

.date {
  font-size: .8rem;
  color: rgb(92, 91, 91);
}
.description {
  font-size: .9rem;
  color: rgb(92, 91, 91);
}

.classe {
  width: 95%!important;
}

a {
  color: black!important;
}
</style>