<template>
  <div>
    Login
  </div>
</template>

<script>
export default {
  name: "LoginPage",
}
</script>