import gql from 'graphql-tag'
import renderPipeline from '../fragments/renderPipeline'

export const query = gql`
  query FindManyRenderPipeline($where: RenderPipelineWhereInput, $orderBy: [RenderPipelineOrderByWithRelationInput]) {
    findManyRenderPipeline(where: $where, orderBy: $orderBy) {
      ...RenderPipelineFragment
    }
  }
  ${renderPipeline}
`

export default query
