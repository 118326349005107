<template>
  <div>
    <ConfirmDialog ref="confirm" />
    <v-alert
      class="mt-4 mb-0"
      type="info"
      text
    >
      <v-row>
        <v-col
          cols="9"
        >
          As an owner of the project, you have rights to edit it.
        </v-col>
        <v-spacer>
          <v-col class="d-flex flex-row-reverse">
            <v-btn
              class="ml-2"
              v-for="button in buttons"
              :key="button.name"
              :color="button.color"
              small
              fab
              elevation="0"
              @click="button.action ? button.action() : () => {}"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on">
                    {{ button.icon }}
                  </v-icon>
                </template>
                <span> {{ button.tooltip }} </span>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-spacer>
      </v-row>
    </v-alert>
    <EditProjectDialog
      v-model="dialog"
      v-if="dialog"
      :project="project"
      justify="center"
    />
  </div>
</template>

<script>
import EditProjectDialog from '../EditProjectDialog.vue';
import ConfirmDialog from "@/components/ConfirmDialog.vue"

export default {
  name: "ProjectOwnerPanel",
  props: {
    project: {
      type: Object,
      default:  () => ({})
    },
  },
  components: {
    EditProjectDialog,
    ConfirmDialog
  },
  data: function () {
    return {
      dialog: false,
      buttons: [
        {
          name: 'Edit',
          icon: 'mdi-pencil-outline',
          color: 'primary',
          tooltip: 'Edit project',
          action: () => {
            this.dialog = true
          }
        },
        {
          name: 'Delete',
          icon: 'mdi-trash-can-outline',
          color: 'red',
          tooltip: 'Delete project',
          action: async () => {
            try {
              if (
                await this.$refs.confirm.open(
                  "Are you sure?",
                  "This Project will be <b>deleted permanently</b>."
                )
              ) {
                this.deleteOldImage()
                await this.$apolloHelper.deleteManyComment.mutate(this.project.id)
                await this.$apolloHelper.deleteManyTestedVersion.mutate(this.project.id)
                await this.$apolloHelper.deleteOneProject.mutate(this.project.id)
                this.$router.push(`/`)
              }
            } catch (error) {
              console.log(error)
            }
          }
        }
      ],
    }
  },
  methods: {
    async deleteOldImage () {
      const imgName = this.project.imageLink.split('/view/')[1]
      try {
        await this.$api.get(`uploads/remove/${imgName}`)
      } catch (error) {
        console.log(error)
      }
    }
  }

}

</script>

<style lang="scss">

::v-deep div.v-alert__wrapper .v-alert__content{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>