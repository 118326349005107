<template>
  <v-dialog
    v-model="show"
    max-width="650"
  >
    <v-card
      :loading="$apollo.queries.user.loading || loading"
      :disabled="loading"
    >
      <template slot="progress">
        <v-progress-linear
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-container class="pa-4 px-7">
        <h3 class="py-3">Add project</h3>
        <v-divider class="pb-3" />
        <v-form
          ref="form"
          v-model="valid"
        >
          <div class="d-flex flex-column justify-start mb-3">
            <TextInputField title="Title" label="Project name" class="my-2" :required="true" :rules="titleRules" v-model="name" />
            <TextInputField title="Link" label="https//github.com/project-link" class="my-2" :required="true" :rules="linkRules" v-model="link" />
            <div class="d-flex flex-row">
              <SelectInputField title="Render Pipeline" label="Select Render Pipelines" class="my-2 mr-2" :required="true" :items="renderPipeline"  v-model="selectedRenderPipelines" :rules="renderPipelineRules" />
              <SelectInputField title="Platform" label="Select supported platforms" class="my-2" :required="true" :items="platform" v-model="selectedPlatforms" :rules="platformRules" />
            </div>
            <SelectInputField title="Project owners" label="Select owners" class="my-2" :items="user" v-model="selectedUsers" :loading="$apollo.queries.user.loading" />
            <div class="d-flex flex-row">
              <SelectInputField title="Primary Features" label="Select up to 5" class="my-2 mr-2" :required="true" :items="filteredPrimaryTags" v-model="selectedPrimaryTags" :rules="tagsRules" />
              <SelectInputField title="Features" label="Other project features" class="my-2" :items="filteredTags" v-model="selectedTags" />
            </div>
            <div class="d-flex flex-row">
              <SelectInputField title="Project version in source" label="Select Unity Stream" class="my-2 mr-2" :required="true" :multiple="false" :items="unityStream" v-model="selectedUnityVersion" :rules="unityStreamRules" />
              <TextInputField title="Unity version" label="E.g. 0f12" class="my-2" :required="true" :rules="unityVersionRules" v-model="unityVersion" />
            </div>
            <TextAreaField title="Description" label="Plain text or markdown" class="my-2" :additionalInfo="true" v-model="description" />
            <FileInputField title="Project cover image" label="Plain text or markdown" additionalInfo="max size 2MB" class="my-2" :rules="imageRules" v-model="image" />
          </div>
        </v-form>
        <v-divider class="pb-3" />
        <div class="d-flex flex-row">
          <v-btn
            class="text-caption text-decoration-underline text-none pa-0"
            text
            @click="resetForm()"
          >
            Clear all
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            @click="createProject()"
            class="px-6 py-5"
            color="primary lighten-1"
            elevation="0"
            small
          >
            Add project
          </v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import TextInputField from '@/components/dialogs/TextInputField.vue'
import SelectInputField from '@/components/dialogs/SelectInputField.vue'
import TextAreaField from '@/components/dialogs/TextAreaField.vue'
import FileInputField from '@/components/dialogs/FileInputField.vue'

export default {
  name: "CreateProjectDialog",
  data: () => ({
    loading: false,
    description: null,
    name: null,
    link: null,
    valid: false,
    image: null,
    selectedUsers: [],
    selectedRenderPipelines: [],
    selectedPlatforms: [],
    selectedPrimaryTags: [],
    selectedTags: [],
    selectedUnityVersion: null,
    unityVersion: null,
    titleRules: [
      v => v !== ' ' || 'Name is required',
      v => v !== null || 'Name is required',
      v => v !== '' || 'Name is required'
    ],
    linkRules: [
      v => v !== ' ' || 'Link is required',
      v => v !== null || 'Link is required',
      v => v !== '' || 'Link is required'
    ],
    renderPipelineRules: [
      v => v.length !== 0 || 'Render Pipeline is required'
    ],
    platformRules: [
      v => v.length !== 0 || 'Supported platform is required'
    ],
    tagsRules: [
      v => v.length !== 0 || 'Primary features are required',
      v => v.length <= 5 || 'Max 5 primary features are allowed'
    ],
    unityStreamRules: [
      v => v > 0 || 'Version is required'
    ],
    unityVersionRules: [
      v => v !== ' ' || 'Unity version is required',
      v => v !== null || 'Unity version is required',
      v => v !== '' || 'Unity version is required'
    ],
    imageRules: [
      value => !value || value.size < 2000000 || 'Project image size should be less than 2 MB!' // 700KB
    ],
  }),
  props: {
    value: Boolean
  },
  components: {
    TextInputField,
    SelectInputField,
    TextAreaField,
    FileInputField
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    filteredPrimaryTags: function() {
      if (this.tag) {
      let tempPrimaryTags = this.tag
      return tempPrimaryTags
      .filter(project => {
          return this._.every(this.selectedTags, tag => {
            return (project.id) !== (tag)
          })
        })
      } return null
    },
    filteredTags: function() {
      if (this.tag) {
      let tempTags = this.tag
      return tempTags
      .filter(project => {
          return this._.every(this.selectedPrimaryTags, tag => {
            return (project.id) !== (tag)
          })
        }) 
      } return null
    },
    createPayload () {
      let fullVersion = null;
      if (this.selectedUnityVersion && this.unityVersion) { fullVersion = this.unityStream.filter(x => x.id === this.selectedUnityVersion)
        fullVersion = fullVersion[0].name + '.' + this.unityVersion
      } 
      return {
        data: {
          name: this.name,
          link: this.link,
          renderPipelines: {
            connect: this.convertToObject(this.selectedRenderPipelines),
          },
          platforms: {
            connect: this.convertToObject(this.selectedPlatforms),
          },
          owners: {
            connect: this.convertToObject(this.selectedUsers),
          },
          primaryTags: {
            connect: this.convertToObject(this.selectedPrimaryTags),
          },
          tags: {
            connect: this.convertToObject(this.selectedTags),
          },
          unityStreamVersion: {
            connect: { id: this.selectedUnityVersion}
          },
          unityVersion: fullVersion,

          description: this.description,
          addedByUser: {
            connect: {
              id: "0"
            }
          },
        }
      }
    },
  },
  apollo: {
    platform () {
      return {
        ...this.$apolloHelper.findManyPlatform,
      }
    },
    tag () {
      return { 
        ...this.$apolloHelper.findManyTag,
      }
    },
    unityStream () {
      return {
        ...this.$apolloHelper.findManyUnityStream,
      }
    },
    renderPipeline () {
      return { 
        ...this.$apolloHelper.findManyRenderPipeline,
      }
    },
    user () {
      return {
        ...this.$apolloHelper.findManyUser,
      }
    },
    me () {
      return {
        ...this.$apolloHelper.me
      }
    }
  },
  async created () {
    if(this.me.id)
      this.selectedUsers = [this.me.id, ...this.selectedUsers]
  },
  methods: {
    async createProject () {
      this.loading = true
      try {
        const { id: projectId } = await this.$apolloHelper.createOneProject.mutate(this.createPayload)
        if (this.image) {
          const image = new FormData()
          image.append('file-to-upload', this.image)
          const { data: imageData } = await this.$api.post(
            '/uploads/upload',
            image,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          const viewImageLink = imageData.output.viewImage
          try {
            await this.$apolloHelper.updateOneProjectImage.mutate(
              {
                where: {
                  id: projectId
                },
                data: {
                  imageLink: { set: viewImageLink },
                }
              }
            )
          } catch (error) {
            console.error(error)
          }
        }
        this.resetForm()
        this.show = false
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    convertToObject (idsArray) {
      if(idsArray)
        return idsArray.map((tag) =>({id: tag}))
    },
    resetForm () {
      this.$refs.form.resetValidation()
      this.$refs.form.reset()
    }
  }
} 
</script>

<style lang="scss" scoped>
</style>