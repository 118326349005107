import Vue from 'vue'
import VueApollo from 'vue-apollo'

import { setContext } from '@apollo/client/link/context'
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core'

Vue.use(VueApollo)

const httpLink = new HttpLink({
  uri: location.protocol === 'https:'
    ? `https://api.project-repository.${process.env.CLUSTER}.ds.unity3d.com/graphql`
    : 'http://localhost:3000/graphql'
})

const authMiddleware = setContext(
  async (operation, config) => {
    const token = await Vue.prototype.$auth.getIdToken()
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`
    }
    return config
  }
)

const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache,
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

export default apolloProvider