import { defineStore } from 'pinia'

import { ref } from '@vue/composition-api'
import { useLocalStorage } from '@vueuse/core'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      oktaUser: ref(useLocalStorage('oktaUser', null)),
    }
  },
  actions: {
    set (field, value) {
      this[field] = value
    }
  }
})
