import gql from 'graphql-tag'
import project from '../fragments/project'

export const query = gql`
  query FindManyProject($where: ProjectWhereInput, $orderBy: [ProjectOrderByWithRelationInput]) {
    findManyProject(where: $where, orderBy: $orderBy) {
      ...ProjectFragment
    }
  }
  ${project}
`

export default query
