import Vue from 'vue'
import VueRouter from 'vue-router'

import OktaVue, { LoginCallback } from '@okta/okta-vue'
import { OktaAuth } from '@okta/okta-auth-js'

import HomePage from '@/pages/Home'
import ProjectPage from '@/pages/Project'
import LoginPage from '@/pages/Login'

Vue.use(VueRouter)

Vue.use(
  OktaVue,
  {
    oktaAuth: new OktaAuth({
      issuer: process.env.OKTA_DOMAIN,
      clientId: process.env.OKTA_CLIENT_ID,
      redirectUri: `${window.location.origin}/implicit/callback`
    })
  }
)

const routes = [
  {
    path: '/login',
    component: LoginPage
  },
  {
    path: '/implicit/callback',
    component: LoginCallback
  },
  {
    path: '/',
    component: HomePage,
    meta: {
      requiresAuth: true
    },
    children: []
  },
  {
    path: '/project/:id',
    component: ProjectPage,
    meta: {
      requiresAuth: true
    },
    children: []
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
