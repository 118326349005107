import gql from 'graphql-tag'
import unityStream from '../fragments/unityStream'

export const query = gql`
  fragment TestedVersionExpandedFragment on TestedVersion {
    id
    fullVersion
    notes
    status
    created
    projectId
    user {
      id
      name
      slackId
      slackPicture
    }
    stream {
      ...UnityStreamFragment
    }
  }
  ${unityStream}
  
`

export default query