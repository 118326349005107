<template>
  <v-dialog
    v-model="show"
    max-width="650"
  >
    <v-card
      :loading="$apollo.queries.user.loading || loading"
      :disabled="loading"
    >
      <template slot="progress">
        <v-progress-linear
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-container class="pa-4 px-7">
        <h3 class="py-3">Update project</h3>
        <v-divider class="pb-3" />
        <v-form
          ref="form"
          v-model="valid"
        >
          <div class="d-flex flex-column justify-start mb-3">
            <TextInputField title="Title" label="Project name" class="my-2" :required="true" :rules="titleRules" v-model="name" />
            <TextInputField title="Link" label="https//github.com/project-link" class="my-2" :required="true" :rules="linkRules" v-model="link" />
            <div class="d-flex flex-row">
              <SelectInputField title="Render Pipeline" label="Select Render Pipelines" class="my-2 mr-2" :required="true" :items="renderPipeline"  v-model="selectedRenderPipelines" :rules="renderPipelineRules" />
              <SelectInputField title="Platform" label="Select supported platforms" class="my-2" :required="true" :items="platform" v-model="selectedPlatforms" :rules="platformRules" />
            </div>
            <SelectInputField title="Project owners" label="Select owners" class="my-2" :items="user" v-model="selectedUsers" />
            <div class="d-flex flex-row">
              <SelectInputField title="Primary Features" label="Select up to 5" class="my-2 mr-2" :required="true" :items="filteredPrimaryTags" v-model="selectedPrimaryTags" :rules="tagsRules" />
              <SelectInputField title="Features" label="Other project features" class="my-2" :items="filteredTags" v-model="selectedTags" />
            </div>
            <div class="d-flex flex-row">
              <SelectInputField title="Project version in source" label="Select Unity Stream" class="my-2 mr-2" :required="true" :multiple="false" :items="unityStream" v-model="selectedUnityVersion" :rules="unityStreamRules" />
              <TextInputField title="Unity version" label="E.g. 0f12" class="my-2" :required="true" :rules="unityVersionRules" v-model="unityVersion" />
            </div>
            <TextInputField title="Project size" label="E.g. 200MB" class="my-2" v-model="size" />
            <TextAreaField title="Description" label="Plain text or markdown" class="my-2" :additionalInfo="true" v-model="description" />
            <FileInputField title="Change cover image" label="Plain text or markdown" additionalInfo="max size 2MB" class="my-2" :rules="imageRules" v-model="newImage" />
          </div>
        </v-form>
        <v-divider class="pb-3" />
        <div class="d-flex flex-row">
          <v-btn
            class="text-caption text-decoration-underline text-none pa-0"
            text
            @click="fillOldData()"
          >
            Reset to old data
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="(!valid) || ($apollo.queries.user.loading)"
            @click="updateProject()"
            class="px-6 py-5"
            color="primary lighten-1"
            elevation="0"
            small
          >
            Update project
          </v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import TextInputField from '@/components/dialogs/TextInputField.vue'
import SelectInputField from '@/components/dialogs/SelectInputField.vue'
import TextAreaField from '@/components/dialogs/TextAreaField.vue'
import FileInputField from '@/components/dialogs/FileInputField.vue'

export default {
  name: "EditProjectDialog",
  data: () => ({
    loading: false,
    description: null,
    name: null,
    link: null,
    valid: false,
    newImage: null,
    selectedUsers: [],
    selectedRenderPipelines: [],
    selectedPlatforms: [],
    selectedPrimaryTags: [],
    selectedTags: [],
    selectedUnityVersion: null,
    unityVersion: null,
    img: null, //delete
    size: null,
    titleRules: [
      v => v !== ' ' || 'Name is required',
      v => v !== null || 'Name is required',
      v => v !== '' || 'Name is required'
    ],
    linkRules: [
      v => v !== ' ' || 'Link is required',
      v => v !== null || 'Link is required',
      v => v !== '' || 'Link is required'
    ],
    renderPipelineRules: [
      v => v.length !== 0 || 'Render Pipeline is required'
    ],
    platformRules: [
      v => v.length !== 0 || 'Supported platform is required'
    ],
    tagsRules: [
      v => v.length !== 0 || 'Primary features are required',
      v => v.length <= 5 || 'Max 5 primary features are allowed'
    ],
    unityStreamRules: [
      v => v > 0 || 'Version is required'
    ],
    unityVersionRules: [
      v => v !== ' ' || 'Unity version is required',
      v => v !== null || 'Unity version is required',
      v => v !== '' || 'Unity version is required'
    ],
    imageRules: [
      value => !value || value.size < 2000000 || 'Project image size should be less than 2 MB!' // 700KB
    ],
  }),
  props: {
    value: Boolean,
    project: {
      type: Object,
      default:  () => ({})
    },
  },
  components: {
    TextInputField,
    SelectInputField,
    TextAreaField,
    FileInputField
  },
  computed: {
    show: {
      get () {
        if(this.project != {}) this.fillOldData()
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    filteredPrimaryTags: function() {
      if (this.tag) {
      let tempPrimaryTags = this.tag
      return tempPrimaryTags
      .filter(project => {
          return this._.every(this.selectedTags, tag => {
            return (project.id) !== (tag)
          })
        })
      } return null
    },
    filteredTags: function() {
      if (this.tag) {
      let tempTags = this.tag
      return tempTags
      .filter(project => {
          return this._.every(this.selectedPrimaryTags, tag => {
            return (project.id) !== (tag)
          })
        })
      } return null
    },
    createPayload () {
      let fullVersion = null;
      if (this.selectedUnityVersion && this.unityVersion) { fullVersion = this.unityStream.filter(x => x.id === this.selectedUnityVersion)
        fullVersion = fullVersion[0].name + '.' + this.unityVersion
      }
      return {
        where: {
          id: this.project.id
        },
        data: {
          name: { set: this.name },
          link: { set: this.link },
          imageLink: { set: this.img }, // delete in prd
          projectSize: { set: this.size },
          renderPipelines: {
            disconnect: this.convertToObject(this.disconnectValues(this.selectedRenderPipelines, this.project.renderPipelines.map(a => a.id))),
            connect: this.convertToObject(this.connectValues(this.selectedRenderPipelines, this.project.renderPipelines.map(a => a.id)))
          },
          platforms: {
            disconnect: this.convertToObject(this.disconnectValues(this.selectedPlatforms, this.project.platforms.map(a => a.id))),
            connect: this.convertToObject(this.connectValues(this.selectedPlatforms, this.project.platforms.map(a => a.id))),
          },
          owners: {
            disconnect: this.convertToObject(this.disconnectValues(this.selectedUsers, this.project.owners.map(a => a.id))),
            connect: this.convertToObject(this.connectValues(this.selectedUsers, this.project.owners.map(a => a.id))),
          },
          primaryTags: {
            disconnect: this.convertToObject(this.disconnectValues(this.selectedPrimaryTags, this.project.primaryTags.map(a => a.id))),
            connect: this.convertToObject(this.connectValues(this.selectedPrimaryTags, this.project.primaryTags.map(a => a.id))),
          },
          tags: {
            disconnect: this.convertToObject(this.disconnectValues(this.selectedTags, this.project.tags.map(a => a.id))),
            connect: this.convertToObject(this.connectValues(this.selectedTags, this.project.tags.map(a => a.id))),
          },
          unityStreamVersion: {
            connect: { id: this.selectedUnityVersion}
          },
          unityVersion: { set: fullVersion },
          description: { set: this.description },
        }
      }
    },
  },
  apollo: {
    platform () {
      return {
        ...this.$apolloHelper.findManyPlatform,
      }
    },
    tag () {
      return { 
        ...this.$apolloHelper.findManyTag,
      }
    },
    unityStream () {
      return {
        ...this.$apolloHelper.findManyUnityStream,
      }
    },
    renderPipeline () {
      return { 
        ...this.$apolloHelper.findManyRenderPipeline,
      }
    },
    user () {
      return {
        ...this.$apolloHelper.findManyUser,
      }
    }
  },
  methods: {
    async deleteOldImage () {
      const imgName = this.project.imageLink.split('/view/')[1]
      try {
        await this.$api.get(`uploads/remove/${imgName}`)
      } catch (error) {
        console.log(error)
      }
    },
    async addNewImage() {
      try {
        const image = new FormData()
        image.append('file-to-upload', this.newImage)
        const { data: imageData } = await this.$api.post(
          '/uploads/upload',
          image,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        const viewImageLink = imageData.output.viewImage
        try {
          await this.$apolloHelper.updateOneProject.mutate(
            {
              where: {
                id: this.project.id
              },
              data: {
                imageLink: { set: viewImageLink },
              }
            }
          )
        } catch (error) {
          console.error(error)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateProject () {
      this.loading = true
      try {
        if (this.newImage) {
          if (this.project.imageLink) this.deleteOldImage()
          this.addNewImage()
        }
        await this.$apolloHelper.updateOneProject.mutate(this.createPayload)
        this.loading = false
        this.show = false
      } catch (error) {
        console.log(error)
      }
    },
    convertToObject (idsArray) {
      if(idsArray)
        return idsArray.map((tag) =>({id: tag}))
    },
    resetForm () {
      this.$refs.form.resetValidation()
      this.$refs.form.reset()
    },
    fillOldData() {
      this.name = this.project.name
      this.link = this.project.link
      this.description = this.project.description
      this.selectedRenderPipelines = this.project.renderPipelines.map(a => a.id) 
      this.selectedPlatforms = this.project.platforms.map(a => a.id) 
      this.selectedUsers = this.project.owners.map(a => a.id) 
      this.selectedPrimaryTags = this.project.primaryTags.map(a => a.id) 
      this.selectedTags = this.project.tags.map(a => a.id) 
      this.selectedUnityVersion = this.project.streamId
      this.size = this.project.projectSize
      this.img = this.project.imageLink
      this.unityVersion = this.project.unityVersion.split('.')[2]
    },
    disconnectValues (oldValues, newValues) {
      return this._.difference(newValues, oldValues)
    },
    connectValues (oldValues, newValues) {
      return this._.difference(oldValues, newValues)
    }
  }
} 
</script>

<style lang="scss" scoped>
</style>