import gql from 'graphql-tag'
import testedVersionExpanded from '../fragments/testedVersionExpanded'

export const query = gql`
  mutation CreateOneTestedVersion($data: TestedVersionCreateInput!) {
    createOneTestedVersion(data: $data) { ...TestedVersionExpandedFragment }
  }
  ${testedVersionExpanded}
`

export default query
