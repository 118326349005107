import gql from 'graphql-tag'

export const query = gql`
  query FindManyUser($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput]) {
    findManyUser(where: $where, orderBy: $orderBy) {
      id,
      name,
      slackPicture
    }
  }
`

export default query
