import gql from 'graphql-tag'

export const query = gql`
  mutation DeleteOneComment($where: CommentWhereUniqueInput!) {
    deleteOneComment(where: $where) {
      id
    }
  }
`

export default query
