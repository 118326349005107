import gql from 'graphql-tag'
import tag from '../fragments/tag'

export const query = gql`
  query FindManyTag($where: TagWhereInput, $orderBy: [TagOrderByWithRelationInput]) {
    findManyTag(where: $where, orderBy: $orderBy) {
      ...TagFragment
    }
  }
  ${tag}
`

export default query
