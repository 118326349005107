import gql from 'graphql-tag'
import platform from '../fragments/platform'

export const query = gql`
  query FindManyPlatform($where: PlatformWhereInput, $orderBy: [PlatformOrderByWithRelationInput]) {
    findManyPlatform(where: $where, orderBy: $orderBy) {
      ...PlatformFragment
    }
  }
  ${platform}
`

export default query
