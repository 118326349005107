import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import pinia from './pinia'

import './plugins/api'

import router from './router'

import apolloProvider from './apollo'
import apolloHelper from './plugins/apolloHelper'

import VueMoment from 'vue-moment'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import VueCompositionApi from '@vue/composition-api'

Vue.use(VueCompositionApi)

Vue.prototype.env = process.env;

Vue.config.productionTip = false

Vue.use(apolloHelper)

Vue.use(VueLodash, { lodash })

Vue.use(VueMoment)

new Vue({
  pinia,
  router,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
