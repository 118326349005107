<template>
  <div>
    <v-row class="ma-2">
      <v-chip v-for="item of owners" :key="item.name" class="black--text mr-2 my-2 py-5 pr-3 pl-5" pill color="primary" :disabled="!item.isActive"
        outlined label>
        <a @click="openTab(item.slackId)">
          <v-avatar v-if="item.slackPicture" left size="18" class="mr-3">
            <v-img :src="item.slackPicture"></v-img>
          </v-avatar>
          <v-avatar v-else size="18" left class="">
            <v-icon size="35" dark>
              mdi-account-circle
            </v-icon>
          </v-avatar>
          {{ item.name }}
        </a>
      </v-chip>
      <v-alert v-if="(!owners.length) || (!owners.filter((owner) => owner.isActive !== 1).length)" class="my-2 py-3" width="100%" icon="mdi-information-variant" prominent dense text
        color="blue-grey darken-1">
        <v-row class="d-flex justify-space-between mx-2">
          <v-col class="d-flex align-center">Project has no owners or no active owners.</v-col>
          <v-col class="d-flex justify-end">
            <v-btn class="px-7 py-5" color="primary lighten-1" elevation="0" small @click="setMeAsOwner()">
              Set yourself owner
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ProjectOwners",
  props: {
    owners: {
      type: Array,
      default: () => ([])
    },
    project: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
  }),
  methods: {
    async setMeAsOwner() {
      try {
        await this.$apolloHelper.updateOneUserOwned.mutate(this.project.id)
      } catch (error) {
        console.log(error)
      }
    },
    openTab(userSlackId) {
      window.open("https://unity.slack.com/team/" + userSlackId, "_blank");
    }
  }
}
</script>

<style scoped lang="scss">
// .v-btn {
//   text-transform: uppercase;
//   font-weight: 700;
//   border-radius: 50px;
// }
</style>