import { apolloClient } from "@/apollo";
import _ from "lodash";

import findManyPlatform from "@/gql/queries/findManyPlatform";
import findManyTag from "@/gql/queries/findManyTag";
import findManyUser from "@/gql/queries/findManyUser";
import findManyUnityStream from "@/gql/queries/findManyUnityStream";
import findManyRenderPipeline from "@/gql/queries/findManyRenderPipeline";
import findManyProject from "@/gql/queries/findManyProject";
import findUniqueProject from "@/gql/queries/findUniqueProject";
import findUniqueUser from "@/gql/queries/findUniqueUser";

import createOneComment from "@/gql/mutations/createOneComment";
import createOneProject from "@/gql/mutations/createOneProject";
import createOneTestedVersion from "@/gql/mutations/createOneTestedVersion";

import deleteOneComment from "@/gql/mutations/deleteOneComment";
import deleteManyComment from "@/gql/mutations/deleteManyComment";
import deleteOneProject from "@/gql/mutations/deleteOneProject";
import deleteOneTestedVersion from "@/gql/mutations/deleteOneTestedVersion";
import deleteManyTestedVersion from "@/gql/mutations/deleteManyTestedVersion";

import updateOneUser from "@/gql/mutations/updateOneUser";
import updateOneProject from "@/gql/mutations/updateOneProject";
import updateOneUserFavourites from "@/gql/mutations/updateOneUserFavourites";
import updateOneUserWatched from "@/gql/mutations/updateOneUserWatched";
import updateOneUserOwned from "@/gql/mutations/updateOneUserOwned";
import updateOneTestedVersion from "@/gql/mutations/updateOneTestedVersion";
import updateOneComment from "@/gql/mutations/updateOneComment";

import me from "@/gql/queries/me";

export default {
  async install(Vue) {
    Vue.prototype.$apolloHelper = {
      // MISC
      prependItem(cache, result, queries, queryName) {
        for (const query of queries) {
          cache.updateQuery(query, (data) => ({
            [queryName]: [
              Object.values(result?.data)[0],
              ...(data?.[queryName] || []),
            ],
          }));
        }
      },
      removeItem(cache, id, __typename) {
        cache.evict({ id: cache.identify({ id, __typename }) });
        cache.gc();
      },
      removeItems(cache, ids, __typename) {
        for (const id of ids)
          cache.evict({ id: cache.identify({ id, __typename }) });
        cache.gc();
      },
      // Platform
      findManyPlatform: {
        query: findManyPlatform,
        variables: {
          orderBy: [{ name: "asc" }],
        },
        update: ({ findManyPlatform: items }) => items,
      },
      // Tag
      findManyTag: {
        query: findManyTag,
        variables: {
          orderBy: [{ name: "asc" }],
        },
        update: ({ findManyTag: items }) => items,
      },
      // RenderPipeline
      findManyRenderPipeline: {
        query: findManyRenderPipeline,
        variables: {
          orderBy: [{ name: "asc" }],
        },
        update: ({ findManyRenderPipeline: items }) => items,
      },
      // Project
      createOneProject: {
        mutation: createOneProject,
        orderBy: [{ created: "desc" }],
        mutationWithVariables: function (variables) {
          return {
            mutation: this.mutation,
            variables,
            update: (cache, result) => {
              const q = {
                query: Vue.prototype.$apolloHelper.findManyProject.query,
                variables: {
                  orderBy: [{ created: "desc" }],
                },
              };
              cache.updateQuery(q, (data) => {
                const clone = _.cloneDeep(data);
                clone.findManyProject = [
                  result.data.createOneProject,
                  ...clone.findManyProject,
                ];
                return clone;
              });
            },
          };
        },
        mutate: async function (variables) {
          const {
            data: { createOneProject: output },
          } = await apolloClient.mutate(this.mutationWithVariables(variables));
          return output;
        },
      },
      findUniqueProject: {
        query: findUniqueProject,
        update: ({ findUniqueProject: item }) => {
          let obj = {
            ...item,
            allTags: [
              ...item.primaryTags.map((item) => ({
                ...item,
                type: "PrimaryTag",
              })),
              ...item.tags,
            ],
          };
          return obj;
        },
      },
      findManyProject: {
        query: findManyProject,
        variables: {
          orderBy: [{ created: "desc" }],
        },
        update: ({ findManyProject: items }) => {
          return items.map((item) => ({
            ...item,
            allTags: [
              ...item.renderPipelines.map((item) => ({
                ...item,
                type: "RenderPipeline",
              })),
              ...item.primaryTags.map((item) => ({
                ...item,
                type: "PrimaryTag",
              })),
              ...item.tags,
            ],
            verifiedVersions: [
              item.unityStreamVersion,
              ...item.testedVersions
                .filter((prop) => {
                  if (prop.status === "PASS") return prop;
                })
                .map((prop) => {
                  return prop.stream;
                }),
            ]
              .map((item) => {
                return {
                  ...item,
                  metadata: item.name.split(".").map((number) => {
                    return parseInt(number);
                  }),
                };
              })
              .sort((a, b) => {
                return (
                  a.metadata[0] - b.metadata[0] || a.metadata[1] - b.metadata[1]
                );
              })
              .reverse(),
          }));
        },
      },
      deleteOneProject: {
        mutation: deleteOneProject,
        mutationWithVariables: function (id) {
          return {
            mutation: this.mutation,
            variables: { where: { id } },
            update: (cache) => {
              Vue.prototype.$apolloHelper.removeItem(cache, id, "Project");
            },
          };
        },
        mutate: async function (id) {
          const {
            data: { deleteOneProject: output },
          } = await apolloClient.mutate(this.mutationWithVariables(id));
          return output;
        },
      },
      updateOneProject: {
        mutation: updateOneProject,
        mutationWithVariables: function (variables) {
          return {
            mutation: this.mutation,
            variables,
            update: (cache, result) => {
              const q = {
                query: Vue.prototype.$apolloHelper.findUniqueProject.query,
                variables: { where: { id: result.data.updateOneProject.id } },
              };
              cache.updateQuery(q, (data) => {
                const clone = _.cloneDeep(data);
                clone.findUniqueProject.name =
                  result.data.updateOneProject.name;
                clone.findUniqueProject.description =
                  result.data.updateOneProject.description;
                clone.findUniqueProject.link =
                  result.data.updateOneProject.link;
                clone.findUniqueProject.renderPipelines =
                  result.data.updateOneProject.renderPipelines;
                clone.findUniqueProject.platforms =
                  result.data.updateOneProject.platforms;
                clone.findUniqueProject.tags =
                  result.data.updateOneProject.tags;
                clone.findUniqueProject.primaryTags =
                  result.data.updateOneProject.primaryTags;
                clone.findUniqueProject.unityVersion =
                  result.data.updateOneProject.unityVersion;
                clone.findUniqueProject.unityStreamVersion =
                  result.data.updateOneProject.unityStreamVersion;
                clone.findUniqueProject.projectSize =
                  result.data.updateOneProject.projectSize;
                clone.findUniqueProject.owners =
                  result.data.updateOneProject.owners;
                clone.findUniqueProject.imageLink =
                  result.data.updateOneProject.imageLink;
                return clone;
              });
            },
          };
        },
        mutate: async function (variables) {
          const {
            data: { updateOneProject: output },
          } = await apolloClient.mutate(this.mutationWithVariables(variables));
          return output;
        },
      },
      updateOneProjectImage: {
        mutation: updateOneProject,
        mutationWithVariables: function (variables) {
          return {
            mutation: this.mutation,
            variables,
            update: (cache, result) => {
              const q = {
                query: Vue.prototype.$apolloHelper.findManyProject.query,
                variables: {
                  orderBy: [{ created: "desc" }],
                },
              };
              cache.updateQuery(q, (data) => {
                const clone = _.cloneDeep(data);
                clone.findManyProject.map(project => {
                  if (project.id == result.data.updateOneProject.id)
                  project = {...result.data.updateOneProject};
                })
                return clone;
              });
            },
          };
        },
        mutate: async function (variables) {
          const {
            data: { updateOneProject: output },
          } = await apolloClient.mutate(this.mutationWithVariables(variables));
          return output;
        },
      },
      // UnityStream
      findManyUnityStream: {
        query: findManyUnityStream,
        update: ({ findManyUnityStream: items }) => {
          return items
            .map((item) => ({
              ...item,
              metadata: item.name.split(".").map((number) => {
                return parseInt(number);
              }),
            }))
            .sort((a, b) => {
              return (
                a.metadata[0] - b.metadata[0] || a.metadata[1] - b.metadata[1]
              );
            })
            .reverse();
        },
      },
      // User
      findManyUser: {
        query: findManyUser,
        variables: {
          where: { isActive: { equals: true } },
          orderBy: [{ name: "asc" }],
        },
        update: ({ findManyUser: items }) => items,
      },
      findUniqueUser: {
        query: findUniqueUser,
        update: ({ findUniqueUser }) => {
          return findUniqueUser;
        },
      },
      updateOneUser: {
        mutation: updateOneUser,
        mutationWithVariables: function (variables) {
          return { mutation: this.mutation, variables };
        },
        mutate: async function (variables) {
          const {
            data: { updateOneUser: output },
          } = await apolloClient.mutate(this.mutationWithVariables(variables));
          return output;
        },
      },
      updateOneUserFavourites: {
        mutation: updateOneUserFavourites,
        mutationWithVariables: function (projectId) {
          return {
            mutation: this.mutation,
            variables: { projectId },
            update: (cache, result) => {
              const q = {
                query: Vue.prototype.$apolloHelper.me.query,
              };
              cache.updateQuery(q, (data) => {
                const clone = _.cloneDeep(data);
                if (
                  clone.me.favouriteProjects.length <
                  result.data.updateOneUserFavourites.favouriteProjects.length
                )
                  clone.me.favouriteProjects = [
                    ...result.data.updateOneUserFavourites.favouriteProjects,
                  ];
                else if (
                  clone.me.favouriteProjects.length >
                  result.data.updateOneUserFavourites.favouriteProjects.length
                ) {
                  //const difference = local.filter(({ id: id1 }) => !result2.some(({ id: id2 }) => id2 === id1));
                  clone.me.favouriteProjects =
                    clone.me.favouriteProjects.filter(({ id: id1 }) =>
                      result.data.updateOneUserFavourites.favouriteProjects.some(
                        ({ id: id2 }) => id2 === id1
                      )
                    );
                }
                return clone;
              });
            },
          };
        },
        mutate: async function (projectId) {
          const {
            data: { updateOneUserFavourites: output },
          } = await apolloClient.mutate(this.mutationWithVariables(projectId));
          return output;
        },
      },
      updateOneUserWatched: {
        mutation: updateOneUserWatched,
        mutationWithVariables: function (projectId) {
          return {
            mutation: this.mutation,
            variables: { projectId },
            update: (cache, result) => {
              const q = {
                query: Vue.prototype.$apolloHelper.me.query,
              };
              cache.updateQuery(q, (data) => {
                const clone = _.cloneDeep(data);
                clone.me.watchedProjects = [
                  ...result.data.updateOneUserWatched.watchedProjects,
                ]; //...clone.me.comments.watchedProjects
                return clone;
              });
            },
          };
        },
        mutate: async function (projectId) {
          const {
            data: { updateOneUserWatched: output },
          } = await apolloClient.mutate(this.mutationWithVariables(projectId));
          return output;
        },
      },
      updateOneUserOwned: {
        mutation: updateOneUserOwned,
        mutationWithVariables: function (projectId) {
          return {
            mutation: this.mutation,
            variables: { projectId },
            update: (cache, result) => {
              // const q = {
              //   query: Vue.prototype.$apolloHelper.me.query,
              // }
              // cache.updateQuery(
              //   q,
              //   (data) => {
              //     const clone = _.cloneDeep(data)
              //     clone.me.ownedProjects = [...result.data.updateOneUserOwned.ownedProjects]
              //     return clone
              //   }
              // )
              const q = {
                query: Vue.prototype.$apolloHelper.findUniqueProject.query,
                variables: { where: { id: projectId } },
              };
              cache.updateQuery(q, (data) => {
                const clone = _.cloneDeep(data);
                clone.findUniqueProject.owners.push({
                  id: result.data.updateOneUserOwned.id,
                  __typename: result.data.updateOneUserOwned.__typename,
                  name: result.data.updateOneUserOwned.name,
                  slackId: result.data.updateOneUserOwned.slackId,
                  slackPicture: result.data.updateOneUserOwned.slackPicture,
                });
                return clone;
              });
            },
          };
        },
        mutate: async function (projectId) {
          const {
            data: { updateOneUserWatched: output },
          } = await apolloClient.mutate(this.mutationWithVariables(projectId));
          return output;
        },
      },
      // Comment
      createOneComment: {
        mutation: createOneComment,
        orderBy: [{ created: "desc" }],
        mutationWithVariables: function (variables) {
          return {
            mutation: this.mutation,
            variables,
            update: (cache, result) => {
              const q = {
                query: Vue.prototype.$apolloHelper.findUniqueProject.query,
                variables: {
                  where: { id: result.data.createOneComment.projectId },
                },
              };
              cache.updateQuery(q, (data) => {
                const clone = _.cloneDeep(data);
                clone.findUniqueProject.comments = [
                  result.data.createOneComment,
                  ...clone.findUniqueProject.comments,
                ];
                return clone;
              });
            },
          };
        },
        mutate: async function (variables) {
          const {
            data: { createOneComment: output },
          } = await apolloClient.mutate(this.mutationWithVariables(variables));
          return output;
        },
      },
      deleteOneComment: {
        mutation: deleteOneComment,
        mutationWithVariables: function (id) {
          return {
            mutation: this.mutation,
            variables: { where: { id } },
            update: (cache) => {
              Vue.prototype.$apolloHelper.removeItem(cache, id, "Comment");
            },
          };
        },
        mutate: async function (id) {
          const {
            data: { deleteOneComment: output },
          } = await apolloClient.mutate(this.mutationWithVariables(id));
          return output;
        },
      },
      deleteManyComment: {
        mutation: deleteManyComment,
        mutationWithVariables: function (id) {
          return {
            mutation: this.mutation,
            variables: { where: { projectId: { equals: id } } },
          };
        },
        mutate: async function (ids) {
          const {
            data: { deleteManyComment: output },
          } = await apolloClient.mutate(this.mutationWithVariables(ids));
          return output;
        },
      },
      updateOneComment: {
        mutation: updateOneComment,
        mutationWithVariables: function (variables) {
          return {
            mutation: this.mutation,
            variables,
            update: (cache, result) => {
              const q = {
                query: Vue.prototype.$apolloHelper.findUniqueProject.query,
                variables: {
                  where: { id: result.data.updateOneComment.projectId },
                },
              };
              cache.updateQuery(q, (data) => {
                const clone = _.cloneDeep(data);
                clone.findUniqueProject.comments[
                  clone.findUniqueProject.comments.findIndex(
                    (el) => el.id == result.data.updateOneComment.id
                  )
                ] = result.data.updateOneComment;
                return clone;
              });
            },
          };
        },
        mutate: async function (variables) {
          const {
            data: { updateOneComment: output },
          } = await apolloClient.mutate(this.mutationWithVariables(variables));
          return output;
        },
      },
      // ME
      me: {
        query: me,
        update: ({ me }) => me,
      },
      //Tested version
      createOneTestedVersion: {
        mutation: createOneTestedVersion,
        orderBy: [{ created: "desc" }],
        mutationWithVariables: function (variables) {
          return {
            mutation: this.mutation,
            variables,
            update: (cache, result) => {
              const q = {
                query: Vue.prototype.$apolloHelper.findUniqueProject.query,
                variables: {
                  where: { id: result.data.createOneTestedVersion.projectId },
                },
              };
              cache.updateQuery(q, (data) => {
                const clone = _.cloneDeep(data);
                clone.findUniqueProject.testedVersions = [
                  result.data.createOneTestedVersion,
                  ...clone.findUniqueProject.testedVersions,
                ];
                return clone;
              });
            },
          };
        },
        mutate: async function (variables) {
          const {
            data: { createOneTestedVersion: output },
          } = await apolloClient.mutate(this.mutationWithVariables(variables));
          return output;
        },
      },
      deleteOneTestedVersion: {
        mutation: deleteOneTestedVersion,
        mutationWithVariables: function (id) {
          return {
            mutation: this.mutation,
            variables: { where: { id } },
            update: (cache) => {
              Vue.prototype.$apolloHelper.removeItem(
                cache,
                id,
                "TestedVersion"
              );
            },
          };
        },
        mutate: async function (id) {
          const {
            data: { deleteOneTestedVersion: output },
          } = await apolloClient.mutate(this.mutationWithVariables(id));
          return output;
        },
      },
      deleteManyTestedVersion: {
        mutation: deleteManyTestedVersion,
        mutationWithVariables: function (id) {
          return {
            mutation: this.mutation,
            variables: { where: { projectId: { equals: id } } },
          };
        },
        mutate: async function (ids) {
          const {
            data: { deleteManyTestedVersion: output },
          } = await apolloClient.mutate(this.mutationWithVariables(ids));
          return output;
        },
      },
      updateOneTestedVersion: {
        mutation: updateOneTestedVersion,
        mutationWithVariables: function (variables) {
          return {
            mutation: this.mutation,
            variables,
            update: (cache, result) => {
              const q = {
                query: Vue.prototype.$apolloHelper.findUniqueProject.query,
                variables: {
                  where: { id: result.data.updateOneTestedVersion.projectId },
                },
              };
              cache.updateQuery(q, (data) => {
                const clone = _.cloneDeep(data);
                clone.findUniqueProject.testedVersions[
                  clone.findUniqueProject.testedVersions.findIndex(
                    (el) => el.id == result.data.updateOneTestedVersion.id
                  )
                ] = result.data.updateOneTestedVersion;
                return clone;
              });
            },
          };
        },
        mutate: async function (variables) {
          const {
            data: { updateOneTestedVersion: output },
          } = await apolloClient.mutate(this.mutationWithVariables(variables));
          return output;
        },
      },
    };
  },
};
