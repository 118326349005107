import gql from 'graphql-tag'

export const query = gql`
  mutation DeleteOneProject($where: ProjectWhereUniqueInput!) {
    deleteOneProject(where: $where) {
      id
    }
  }
`

export default query
