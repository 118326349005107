<template>
  <div>
    <div class="d-flex">
      <TestedVersionInformation v-model="showInformation" :item="clickedItem" />
      <TestedVersionDialog v-model="addVersion" :projectId="projectId" />
      <EditTestedVersionDialog v-if="editVersion" v-model="editVersion" :item="clickedItem" />
      <ConfirmDialog ref="confirm" />
      <v-row class="ma-2 d-flex align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="mr-2 my-2 py-5 px-3" v-if="sourceVersion" label v-bind="attrs" v-on="on">
              {{ sourceVersion }}
            </v-chip>
          </template>
          <span>Project version in source</span>
        </v-tooltip>
        <div v-for="item of sortedVersions" :key="item.id" class="mr-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="my-2 py-5 px-3 white--text active-chip" :v-if="versions" @click.self="showItemInfo(item)"
                :class="{ pass: item.status === 'PASS', fail: item.status === 'ISSUES', partly: item.status === 'WORKS_PARTLY' }"
                label v-bind="attrs" v-on="on">
                <span @click.self="showItemInfo(item)">
                  {{ item.fullVersion }}
                </span>
              </v-chip>
            </template>
            <span>Click to open more info</span>
          </v-tooltip>
          <span v-if="item.user.id === me.id" class="mr-1">
            <v-btn @click="button.action ? button.action(item) : () => { }" class="ma-0 pa-0 action-button"
              v-for="button in buttons" :key="button.name" :color="button.color" fab elevation="0" x-small>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="16" v-bind="attrs" v-on="on" color="white">
                    {{ button.icon }}
                  </v-icon>
                </template>
                <span> {{ button.tooltip }} </span>
              </v-tooltip>
            </v-btn>
          </span>
          <span v-else-if="isOwner" class="mr-1">
            <v-btn @click="buttons[1].action ? buttons[1].action(item) : () => { }" class="ma-0 pa-0 action-button"
              :color="buttons[1].color" fab elevation="0" x-small>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="16" v-bind="attrs" v-on="on" color="white">
                    {{ buttons[1].icon }}
                  </v-icon>
                </template>
                <span> {{ buttons[1].tooltip }} </span>
              </v-tooltip>
            </v-btn>
          </span>
        </div>
        <div>

        </div>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn @click="addVersion = true" v-bind="attrs" v-on="on" color="primary" class="mx-2" fab elevation="0"
              small>
              <v-icon size="26" color="white">
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Add tested version</span>
        </v-tooltip>
      </v-row>
    </div>
  </div>
</template>

<script>
import TestedVersionInformation from '@/components/projectPageComponents/TestedVersionInformation.vue'
import TestedVersionDialog from "@/components/TestedVersionDialog.vue"
import EditTestedVersionDialog from "@/components/EditTestedVersionDialog.vue"
import ConfirmDialog from "@/components/ConfirmDialog.vue"

export default {
  name: "ProjectTestedVersions",
  components: {
    TestedVersionInformation,
    TestedVersionDialog,
    EditTestedVersionDialog,
    ConfirmDialog
  },
  props: {
    projectId: String,
    versions: {},
    sourceVersion: {},
  },
  data: function () {
    return {
      me: false,
      editVersion: false,
      showInformation: false,
      clickedItem: {},
      addVersion: false,
      buttons: [
        {
          name: 'Edit',
          icon: 'mdi-pencil-outline',
          color: 'primary',
          tooltip: 'Edit version',
          action: (item) => {
            this.editVersion = true
            this.clickedItem = item
          }
        },
        {
          name: 'Delete',
          icon: 'mdi-trash-can-outline',
          color: 'red',
          tooltip: 'Delete version',
          action: async (item) => {
            try {
              if (
                await this.$refs.confirm.open(
                  "Are you sure?",
                  "This Tested Version will be <b>deleted permanently</b>."
                )
              ) {
                await this.$apolloHelper.deleteOneTestedVersion.mutate(item.id)
              }
              // this.$router.push(`/`)
            } catch (error) {
              console.log(error)
            }
          }
        }
      ],
    }
  },
  methods: {
    showItemInfo(item) {
      this.clickedItem = item
      this.showInformation = true
    }
  },
  computed: {
    sortedVersions: function () {
      let tempVersions = this.versions
        .map(
          (item) => ({
            ...item,
            metadata: item.fullVersion
              .split('.')
              .map(
                (number) => {
                  return parseInt(number)
                }
              )
          })
        )
        .sort(
          (a, b) => {
            return a.metadata[0] - b.metadata[0] || a.metadata[1] - b.metadata[1]
          }
        )
        .reverse()
      return tempVersions
    },
    isOwner() {
      if (this.me) {
        const ownedProjects = this.me.ownedProjects.map(project => project.id)
        return this._.includes(ownedProjects, this.projectId)
      } return false
    },
  },
  apollo: {
    me() {
      return {
        ...this.$apolloHelper.me
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pass {
  background-color: #68a76b !important;
}

.fail {
  background-color: #C62828 !important;
}

.partly {
  background-color: #f0a000 !important;
}


.action-button {
  margin: 0px 0px 0px 3px !important;
  height: 22px;
  width: 22px;
}
</style>