import gql from 'graphql-tag'
import projectExpanded from '../fragments/projectExpanded'

export const query = gql`
  query FindUniqueProject($where: ProjectWhereUniqueInput!) {
    findUniqueProject(where: $where) {
      ...ProjectFragmentExpanded
    }
  }
  ${projectExpanded}
`

export default query
