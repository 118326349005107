import gql from 'graphql-tag'
import platform from '@/gql/fragments/platform'
import renderPipeline from '@/gql/fragments/renderPipeline'
import testedVersion from "@/gql/fragments/testedVersionExpanded"
import tag from "@/gql/fragments/tag"
import unityStream from "@/gql/fragments/unityStream"
import comment from "@/gql/fragments/comment"
import bug from "@/gql/fragments/bug"

export const query = gql`
  fragment ProjectFragmentExpanded on Project {
    id
    name
    link
    imageLink
    description
    projectSize
    unityVersion
    streamId
    created
    platforms {
      ...PlatformFragment
    }
    renderPipelines {
      ...RenderPipelineFragment
    }
    testedVersions {
      ...TestedVersionExpandedFragment
    }
    tags {
      ...TagFragment
    }
    primaryTags {
      ...TagFragment
    }
    comments {
      ...CommentFragment
    }
    owners {
      name
      id
      slackId
      slackPicture
      isActive
    }
    watchers {
      name
      slackId
      slackPicture
    }
    bugs {
      ...BugFragment
    }
  }
  ${unityStream}
  ${platform}
  ${renderPipeline}
  ${testedVersion}
  ${tag}
  ${comment}
  ${bug}
`

export default query