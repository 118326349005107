import gql from 'graphql-tag'
import comment from '../fragments/comment'

export const query = gql`
  mutation UpdateOneComment($where: CommentWhereUniqueInput!, $data: CommentUpdateInput!) {
    updateOneComment(where: $where, data: $data) { ...CommentFragment }
  }
  ${comment}
`

export default query
