import gql from 'graphql-tag'
import testedVersion from '../fragments/testedVersionExpanded'

export const query = gql`
  mutation UpdateOneTestedVersion($where: TestedVersionWhereUniqueInput!, $data: TestedVersionUpdateInput!) {
    updateOneTestedVersion(where: $where, data: $data) { ...TestedVersionExpandedFragment }
  }
  ${testedVersion}
`

export default query
