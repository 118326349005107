import gql from 'graphql-tag'
import unityStream from '../fragments/unityStream'

export const query = gql`
  fragment TestedVersionFragment on TestedVersion {
    id
    stream {
      ...UnityStreamFragment
    }
    status
  }
  ${unityStream}
`

export default query