import gql from 'graphql-tag'
import user from '../fragments/user'

export const query = gql`
  mutation UpdateOneUserWatched($projectId: String!) {
    updateOneUserWatched(projectId: $projectId) {
      ...UserFragment
    }
  }
  ${user}
`

export default query
