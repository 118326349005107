import gql from 'graphql-tag'

export const query = gql`
  fragment CommentFragment on Comment {
    id
    userId
    user {
      id
      name
      slackId
      slackPicture
    }
    projectId
    comment
    created
    updated
  }
`

export default query