<template>
  <div>
    <v-overlay
      v-if="$apollo.queries.project.loading"
      absolute
      color="primary"
    >
      <LoadingAnimation />
    </v-overlay>
    <HomeAppBar />
    <div v-if="!$apollo.queries.project.loading" class="project-content">
      <ProjectBanner :project="project" />
      <OwnerPanel :project="project" v-if="isOwner"/>
      <ProjectTabs :project="project" />
      <ProjectComments v-model="project.comments" :owners="project.owners.map( owner => owner.id)" :projectId="project.id"/>
    </div>
  </div>
</template>

<script>
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import HomeAppBar from '@/components/HomeAppBar.vue'
import ProjectBanner from '@/components/projectPageComponents/Banner.vue'
import ProjectTabs from '@/components/projectPageComponents/Tabs.vue'
import ProjectComments from '@/components/projectPageComponents/Comments.vue'
import OwnerPanel from '@/components/projectPageComponents/OwnerPanel.vue'

export default {
  name: "ProjectPage",
  components: {
    LoadingAnimation,
    HomeAppBar,
    ProjectBanner,
    ProjectTabs,
    ProjectComments,
    OwnerPanel
  },
  data: () => ({
    me: false,
    project: false,
    loading: {
      project: true,
    },
  }),
  computed: {
    isOwner() {
      if (this.me.isAdmin == true) {
        return true }
      else if (this.me) {
        const ownedProjects = this.me.ownedProjects.map(project => project.id)
        return this._.includes(ownedProjects, this.project.id)
      } return false
    },
  },
  apollo: {
    project () {
      return {
        ...this.$apolloHelper.findUniqueProject,
        variables () { return { where: { id: this.$route.params.id } } }
      }
    },
    me () {
      return {
        ...this.$apolloHelper.me
      }
    }
  }
}
</script>

<style scoped lang="scss">
.project-content {
  max-width: 1500px;
  // max-width: 1200px;
  margin: auto;  
}

.project-tab {
  text-transform: capitalize!important;
  font-size: 1rem;
}
</style>