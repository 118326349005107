import gql from 'graphql-tag'
import comment from '../fragments/comment'

export const query = gql`
  mutation CreateOneComment($data: CommentCreateInput!) {
    createOneComment(data: $data) { ...CommentFragment }
  }
  ${comment}
`

export default query
