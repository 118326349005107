import gql from 'graphql-tag'

export const query = gql`
  fragment UserFragment on User {
    id
    email
    name
    slackId
    slackPicture
    isAdmin
    favouriteProjects {
      id
    }
    watchedProjects {
      id
    }
    ownedProjects {
      id
    }
    isActive
  }
`

export default query