import gql from 'graphql-tag'


export const query = gql`
  fragment BugFragment on Bug {
    id
    title
    description
    unityVersion
    status
    user {
      id
      name
      slackId
      slackPicture
    }
    project {
      id
    }
    created
  }
`

export default query