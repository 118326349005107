import gql from 'graphql-tag'
import user from '../fragments/user'

export const query = gql`
  mutation UpdateOneUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
    updateOneUser(where: $where, data: $data) { ...UserFragment }
  }
  ${user}
`

export default query
