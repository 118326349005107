<template>
  <v-menu
    bottom
    min-width="200px"
    rounded
    offset-y
    elevation="0"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        x-large
        v-on="on"
        disabled
      >
        <v-avatar v-if="user.slackPicture" :size=imageSize>
          <img
            :src="user.slackPicture"
            alt="Slack Image"
          >
        </v-avatar>
        <v-avatar v-else left>
          <v-icon size="50" dark color="primary">
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <!-- <v-card
      elevation="0"
    >
      <v-list-item-content class="justify-center" elevation="0">
        <div class="mx-auto text-center">
          <template
            v-for="(item, index) of menuButtons"
          >
            <v-btn
              @click="item.action ? item.action() : () => {}"
              :key="index"
              v-bind="item.bind"
              small
              text
            >
              {{ item.name }}
            </v-btn>
            <v-divider
              v-if="menuButtons.length !== index+1"
              :key="index"
              class="my-3"
            />
          </template>
        </div>
      </v-list-item-content>
    </v-card> -->
  </v-menu>
</template>

<script>

export default {
	name: "AvatarButton",
  props: {
    imageSize: {
      type: String,
      default: '43px'
    },
    value: {
    },
  },
  computed: {
    user: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
	data: () => ({
    menuButtons: [
      // {
      //   name: 'Owned projects'
      // },
      // {
      //   name: 'Logout'
      // }
    ],
	})
}
</script>